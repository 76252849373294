<template>
  <v-row v-if="item" no-gutters>
    <v-col cols="12" lg="6" offset-lg="6">
      <v-list color="transparent" dense tile flat>
        <v-list-item
          v-for="(obItem, index) in arItems"
          :key="`totals-${obItem.label}.${index}`"
        >
          <v-list-item-content>
            <v-list-item-subtitle v-text="$t(obItem.label)" />
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title :class="obItem.css" v-text="obItem.value" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import type { InvoiceData } from "@planetadeleste/vue-mc-gw";
import { currencyFormat } from "@/plugins/helpers";

@Component
export default class InvoicePreviewTotals extends Vue {
  @Prop(Object) readonly item!: InvoiceData;

  get sSubtotal() {
    return currencyFormat(
      this.item.total_price_without_tax_value,
      this.item.currency?.code
    );
  }

  get sTaxes() {
    return currencyFormat(this.item.total_tax_value, this.item.currency?.code);
  }

  get sTotal() {
    return currencyFormat(
      this.item.total_price_value,
      this.item.currency?.code
    );
  }

  get arItems() {
    return [
      { label: "subtotal", value: this.sSubtotal, css: "text-right" },
      { label: "taxes", value: this.sTaxes, css: "text-right" },
      {
        label: "amount.total",
        value: this.sTotal,
        css: "text-h6 font-weight-bold text-right",
      },
    ];
  }
}
</script>
