<template>
  <v-list color="transparent" dense class="pa-0">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle
          v-if="label"
          class="text-caption"
          v-text="label"
        />
        <v-list-item-title>
          <slot name="value">{{ sValue }}</slot>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { isBoolean } from "lodash";

@Component
export default class FieldPreview extends Vue {
  @Prop(String) readonly label!: string;
  @Prop([String, Number, Boolean]) readonly value!: string;

  get sValue() {
    if (isBoolean(this.value)) {
      return this.$t(this.value ? "yes" : "no");
    }

    return this.value;
  }
}
</script>
