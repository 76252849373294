<template>
  <component :is="tag" v-if="!!sName" v-text="sName" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import type { BranchData } from "@planetadeleste/vue-mc-gw";
import { Branch } from "@planetadeleste/vue-mc-gw";
import { get } from "lodash";

@Component
export default class BranchPreview extends Vue {
  @Prop([Number, String]) readonly branchId!: number;
  @Prop(Object) readonly item!: BranchData;
  @Prop({ type: String, default: "span" }) readonly tag!: string;

  obModel: Branch = new Branch();

  get obItem(): BranchData {
    return this.item || this.obModel.attributes;
  }

  get sName() {
    return get(this.obItem, "firm.name");
  }

  async mounted() {
    if (this.branchId && !this.item) {
      this.obModel = new Branch({ id: this.branchId });
      await this.obModel.fetch();
    }
  }
}
</script>
