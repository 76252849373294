<template>
  <v-toolbar
    class="mx-n6"
    color="grey lighten-2"
    flat
    style="max-width: none"
    tile
  >
    <company-preview :company="item.company" hide-title>
      <branch-preview :branch-id="item.branch_id" :item="item.branch" />
    </company-preview>

    <v-spacer />

    <customer-preview v-if="item.customer" :customer="item.customer" />
  </v-toolbar>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import type { InvoiceData } from "@planetadeleste/vue-mc-gw";
import CompanyPreview from "@/modules/companies/components/CompanyPreview.vue";
import BranchPreview from "@/modules/companies/components/BranchPreview.vue";
import CustomerPreview from "@/modules/customers/components/CustomerPreview.vue";

@Component({
  components: { CustomerPreview, BranchPreview, CompanyPreview },
})
export default class InvoicePreviewToolbar extends Vue {
  @Prop(Object) readonly item!: InvoiceData;
}
</script>
